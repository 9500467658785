<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ site.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-form class="py-6">
                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col>
                                    <div class="v-title d-flex justify-space-between align-center pb-2">
                                        Скрипты
                                        <v-spacer />
                                        <v-btn color="primary" class="mr-3" @click="createScript">
                                            <v-icon>mdi-plus</v-icon> Добавить
                                        </v-btn>
                                    </div>
                                    <v-row>
                                        <v-col>
                                            <template v-for="(item, index) in this.setting.scripts">
                                                <div class="elevation-1 pa-4 mt-4" :key="`site-scripts-${index}`">
                                                    {{ item }}<br>
                                                    <div class="d-flex justify-end align-center pt-4">
                                                        <v-btn color="primary" class="mr-3" @click="editScript(index)">
                                                            <v-icon>mdi-pencil</v-icon> Редактировать
                                                        </v-btn>
                                                        <v-btn color="primary" class="mr-3" @click="removeScript(index)">
                                                            <v-icon>mdi-trash-can</v-icon> Удалить
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col>
                                    <div class="v-title pb-2">Дополнительные файлы</div>
                                    <v-row>
                                        <v-col>
                                            <div v-if="this.setting.files.legal_information" class="d-flex">
                                                <v-icon color="primary" class="mr-1">mdi-file</v-icon>
                                                <a :href="imageSrc(this.setting.files.legal_information)" target="_blank">Правовая информация</a>
                                                <v-icon color="error" class="ml-6" @click="removeFile('legal_information')">mdi-trash-can</v-icon>
                                            </div>
                                            <v-file-input
                                                v-else
                                                label="Правовая информация"
                                                outlined
                                                prepend-icon="mdi-file"
                                                hide-details
                                                @change="setFile('legal_information', $event)"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-dialog
            v-model="editScriptModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Скрипт
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-textarea 
                                    v-model="editScriptItem"
                                    outlined
                                    label="Скрипт"
                                    rows="3"
                                    auto-grow
                                    hide-details>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="saveScript"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editScriptModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    import { imageSrc } from '../../helpers';

    export default {
        name: 'EntitySetting',

        components: {
            SiteTabLinks,
        },

        data() {
            return {
                imageSrc,
                setting: {
                    scripts: [],
                    files: {
                        legal_information: null
                    }
                },

                editScriptItem: null,
                editScriptIndex: null,
                editScriptModal: false
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('siteSetting', {
                oldSetting: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const { id } = this.$route.params;
            if(id) {
                await store.dispatch('sites/get', { id });
                await store.dispatch('siteSetting/get', { site_id: id });
                this.setting = this.oldSetting;
                if(!this.setting.scripts) {
                    this.setting.scripts = [];
                }
                if(!this.setting.files) {
                    this.setting.files = {
                        legal_information: null
                    };
                }
            }
        },

        methods: {
            async setFile(prop, file) {
                this.setting.files[prop] = await store.dispatch('files/upload', { file });
            },
            removeFile(prop) {
                this.setting.files[prop] = null;
            },
            createScript() {
                this.editScriptItem = '';
                this.editScriptIndex = this.setting.scripts.length;
                this.setting.scripts.push('');
                this.editScriptModal = true;
            },
            editScript(index) {
                this.editScriptItem = this.setting.scripts[index];
                this.editScriptIndex = index;
                this.editScriptModal = true;
            },
            saveScript() {
                this.setting.scripts[this.editScriptIndex] = this.editScriptItem;
                this.editScriptModal = false;
            },
            removeScript(index) {
                this.setting.scripts.splice(index, 1);
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('siteSetting/SET_ENTITY', this.setting);
                    await store.dispatch('siteSetting/save', { site_id: id });
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/sites/${id}/detail`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>
